import React from 'react';
import AlertDialog from './modal/AlertDialog';
import api from './service/api';
import { debounce } from './util';

const ErrorBoundaryContext = React.createContext((error) => {});

export const useErrorHandler = () => {
  return React.useContext(ErrorBoundaryContext)
}

export const ErrorFallback = ({error, resetErrorBoundary}) => (
  <AlertDialog title='💡 Błąd ogólny' message={"[text]"} portal={true} onClose={resetErrorBoundary} />
)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error : new Error("") };
    this.showReject = false;
    this.logErrorComponent = debounce((error) => api.logError(error), 500);
  }

  static logErrorUncaught = debounce((error) => api.logError(error), 500);
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    ErrorBoundary.logErrorUncaught({
      message: error?.message, 
      stack: error?.stack || "", 
      file_name: error?.fileName || "*",
      line_number: error?.lineNumber || 0
    });
    //console.log("err", error);
    return { hasError: true, error };
  }

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidCatch(_, errorInfo) { // (error, errorInfo)
    this.logErrorComponent({
      message: "React Error component",
      stack: errorInfo?.componentStack || ""
    });
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  promiseRejectionHandler = (e) => {
    console.warn(`UNHANDLED PROMISE REJECTION: ${e.reason}`);
    this.showReject && this.setState({ hasError: true, error: e?.reason });
    e.preventDefault();
    e.stopPropagation();
  }

  triggerError = (_) => {}

  resetError = () => {
    // this.setState({ hasError: false, error : new Error("") });
    window.location.href = '/';
  }

  render() {
    if (this.state.hasError) {
      return (
        <AlertDialog 
          title='💡 Błąd ogólny'
          message={this.state.error?.message}
          portal={true}
          whitespace={false}
          onClose={this.resetError}
        /> 
      );
    }

    return (
      <ErrorBoundaryContext.Provider value={this.triggerError}>
        {this.props.children}
      </ErrorBoundaryContext.Provider>
    );
  }
}

export default ErrorBoundary;
