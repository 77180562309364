import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import getUserData from '../UserData.ts';
import { useGlobalStore, useGlobalStoreUser } from './global';
import { SpinnerFixed } from '../spinner';

const UserConfig = (props) => {
  const [firstRender, setFirstRender] = useState(false);
  const [, globalDispatchUser] = useGlobalStoreUser();
  const [, globalDispatch] = useGlobalStore();
  const navigate = useNavigate();

  useEffect(async () => {
    let cancel = false;
    getUserData().then((userObj) => {
      if (!userObj) {
        navigate('/login');
        return false;
      }

      if (cancel) {
        return false;
      }

      globalDispatchUser.setUser(userObj);
      globalDispatch.setFilter({});
      setFirstRender(true);

      return true;
    });

    return () => {
      cancel = true;
    };
  }, []);

  return (
    <>
      {firstRender === false && <SpinnerFixed />}
      {firstRender === true && props.children}
    </>
  );
};

export default UserConfig;
