import { useState, useEffect, useMemo } from 'react';
import { useQuery, useQueries, useMutation, useInfiniteQuery, useQueryClient } from 'react-query';

const useConcurrentQueries = ({
  queryKey = [],
  queryFn = () => {},
  options = {},
  paramList = [],
  rateLimit = 4,
}) => {
  const [queue, setQueue] = useState([]);
  // console.log("useConcurrentQueries", queue, paramList, rateLimit, Math.min(rateLimit, paramList.length));

  const queries = useQueries(
    paramList.map((param, index) => {
      const query = ({
        queryKey: [...queryKey, param, index],
        queryFn: ({ signal }) => queryFn(param, signal),
        ...options,
        onSuccess: undefined,
        onError: undefined,
        enabled: (options?.enable != undefined && options?.enable != true) ? 
          false : 
          queue?.filter(q => q == 1).length + rateLimit >= index + 1
      });

      return query;
    })
  );
  
  useEffect(() => {
    if (paramList.length == 0) {
      return;
    }

    setQueue([]);
  }, [paramList.length]);

  useEffect(() => {
    // console.log("queries isSuccess", queries.filter(query => query.isSuccess || query.isStale).length);
    // console.log("queries isLoading", queries.filter(query => !query.isLoading).length);

    queries.forEach((query, index) => {
      if (queue[index] > 0) return;
      if (query.status != "success" && query.status != "error") return;
      if (queue[index] === undefined && queue.filter(q => q == 0).length >= rateLimit) return;

      if (queue[index] === undefined) {
        setQueue((prev) => {
          prev[index] = 0;
          return prev;
        });
      }

      if (query.isSuccess && options?.onSuccess) {
        console.log("queries success", index, queue[index]);
        options?.onSuccess(query.data);
      }

      if (query.isError) {
        console.log("queries error", query.error.message);

        if (options?.onError)
          options?.onError(query.error);
      }

      setQueue((prev) => {
        prev[index] = 1;
        return prev;
      });
    });
  }, [queries.filter(query => !query.isLoading).length]);

  if (paramList.length == 0) return [];

  return queries;
};

export function useQueryProcess() {
  const defaultOptions = {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
  };
  const queryClient = useQueryClient();

  function query(key, queryFunction, options = {}) {
    return useQuery({
      queryKey: key,
      queryFn: queryFunction,
      ...defaultOptions,
      ...options,
    });
  }

  function queries(key, funcMap = [], options = {}) {
    return useQueries(
      funcMap.map((func, index) => ({
        queryKey: [...key, index + 1],
        queryFn: func,
        ...defaultOptions,
        ...options,
      }))
    );
  }

  function queryInfinite(key, queryFunction, options = {}) {
    return useInfiniteQuery({
      queryKey: key,
      queryFn: queryFunction,
      ...defaultOptions,
      ...options,
      getNextPageParam: (lastPage, allPages) => lastPage.nextCursor,
      getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
    });
  }

  function mutate(key, mutationFunction, options = {}) {
    return useMutation({
      mutationKey: key,
      mutationFn: mutationFunction,
      onSettled: () => queryClient.invalidateQueries(key),
      ...options,
    });
  }

  function queryConcurrent(key, queryFunction, options = {}, paramList = [], rateLimit = 4) {
    return useConcurrentQueries({
      queryKey: key,
      queryFn: queryFunction,
      options: {
        ...defaultOptions,
        ...options,
      },
      paramList,
      rateLimit,
    });
  }

  return { query, queries, queryInfinite, queryConcurrent, mutate, queryClient };
};
