import { useState, useEffect, useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';

const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  // scroll to top, commented
  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     // console.log("CustomRouter, history%c", "color:green", "scrolltop")
  //     // window.scrollTo(0, 0);
  //   });
  //   return () => {
  //     unlisten();
  //   }
  // }, []);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export default CustomRouter;
