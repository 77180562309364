import { useState, useEffect, useRef, useCallback } from "react";
import api from '../service/api.ts';
import { parseJSON, truncateString } from '../../components/util';
import { useCart } from '../../components/context/cart.ts';

import getUserData from '../UserData.ts';

import { useGlobalStore, useGlobalStoreUser } from '../../components/context/global';
import { useTranslation } from "react-i18next";
import IconSpinner from "../icon/IconSpinner.js";
import AlertDialog from "../modal/AlertDialog.js";

const getBranchList = async () => {
  const resp = await api.getTraderBranch();
  return resp?.data;
};

const postBranch = async (symbol) => {
  const resp = await api.postTraderBranch(symbol);
  return resp?.data;
};

const UserBranch = () => {
  const { t } = useTranslation("shop");
  const [, cartHook] = useCart();
  const [globalStoreUser, globalDispatchUser] = useGlobalStoreUser();
  const [,globalDispatch] = useGlobalStore();

  const [branchList, setBranchList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [chooseBranch, setChooseBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const refList = useRef();
  let isMounted = false;

  const getBranchListMemory = useCallback(() => {
    isMounted && setLoading(true);
    getBranchList().then(data => {
      if (data?.status != 0) return;

      const list = parseJSON(data?.dane);
      if (!list) return;

      isMounted && setChooseBranch(data?.info || "");
      isMounted && setBranchList(list.sort((a, b) => 
        a.nazwa_odb.trim().localeCompare(b.nazwa_odb.trim(), 'pl')
      ));
    }).finally(() => isMounted && setLoading(false));
  }, [globalStoreUser]);

  useEffect(() => {
    isMounted = true;
    getBranchListMemory();
    return () => {
      isMounted = false;
    }
  }, [getBranchListMemory]);

  const onBranchSelect = (symbol_odb) => {
    setShowList(false);
    setLoading(true);
    postBranch(symbol_odb).then(data => {
      if (data?.status != 0) {
        setLoading(false);
        AlertDialog({
          key: "alert_user_branch",
          title: "Komunikat",
          message: data?.info || "Błąd pobrania listy oddziałów",
          color: "yellow"
        })
        return;
      }

      setChooseBranch(symbol_odb);
      
      getUserData().then(userObj => {
        if (!userObj) return false;
        globalDispatchUser.setUser(userObj);
        globalDispatch.setFilter({});
      });

      cartHook.getWholeCart().then(() => {
        console.log("UserBranch cart");
      }).finally(() => setLoading(false));
    });
  };

  const getChooseBranch = () => {
    if (branchList.length <= 0) return '';

    const branch = branchList.find(branch => branch.symbol_odb == chooseBranch);
    if (!branch) return '';

    return `(${branch.nazwa_odb}) `;
  };

  const onBlurList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (refList.current && !refList.current.contains(document.activeElement)) {
        setShowList(false);
      }
    }, 100);
  };

  const onShowList = (e) => {
    e.preventDefault();
    setShowList(!showList);
  };

  return (
    <div>
      <div className="flex-end">
        <div className="inline-block text-left xs:relative">
          <div>
            <button
              onClick={onShowList}
              onBlur={onBlurList}
              type="button"
              className={`relative inline-flex gap-1 text-primary`}
              title={getChooseBranch(chooseBranch)}
              data-symbol={chooseBranch}
            >
              {loading && (
                <span className="absolute block w-full h-full bg-gray-100 bg-opacity-60">
                  <IconSpinner className="absolute w-16 h-4 -translate-x-1/2 left-1/2" />
                </span>
              )}
              {/* <span className={`${loading ? 'rainbow-font' : ''}`}> */}
              <span>
                {t('Oddział')}
                <span translate="no" className="hidden sm:inline-block">
                  &nbsp;{getChooseBranch(chooseBranch)}
                </span>
              </span>
              <svg className="w-4 h-4 mt-px" viewBox="0 0 24 24" fill="none">
                <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <div
            tabIndex={-1}
            ref={refList}
            onBlur={onBlurList}
            className={`origin-top-left absolute left-0 z-20 m-width-20 transition-opacity mt-1 ring-1 ring-black ring-opacity-5 right-0 rounded-md bg-white shadow-lg outline-none ${showList ? 'opacity-100 visible' : 'invisible opacity-0'}`}
            style={{minWidth: "280px"}}
          >
            <div className="relative">
              <ul
                role="listbox"
                aria-labelledby="userbranch-label"
                aria-activedescendant="userbranch-0"
                className="py-1 overflow-auto text-sm focus:outline-none sm:text-sm"
                style={{ maxHeight: "349px" }}
              >
                {branchList.length == 0 && (
                  <div className="relative px-2 py-2 text-center text-yellow-700 transition bg-yellow-100 rounded" role="alert">
                    <span className="block text-sm sm:inline">{t('Brak oddziałów')}</span>
                  </div>
                )}
                {branchList.length > 0 && branchList
                  .sort((a, b) => (a.symbol_odb == chooseBranch && b.symbol_odb != chooseBranch) ? -1 : 1)
                  .map((val) =>
                    <li 
                      key={`userbranch_choose_${val.symbol_odb}`} 
                      id={`userbranch-${val.symbol_odb}`} 
                      role="option" 
                      aria-selected="false" 
                      className={`group relative py-2 pl-3 pr-6 cursor-default select-none border-t first:border-t-0 hover:text-white hover:bg-primary 
                        ${val.symbol_odb == chooseBranch ? 'bg-primary text-white' : 'text-gray-700'}`}
                    >
                      <button
                        type="button"
                        onMouseDown={(e) => { onBranchSelect(val.symbol_odb); }}
                        className={`w-full outline-none`}
                      >
                        <div
                          className="flex flex-col items-start"
                          translate="no"
                        >
                          {/* <div className="block -mt-px overflow-hidden text-sm font-semibold truncate whitespace-nowrap overflow-ellipsis" style={{ maxWidth: "220px" }}> */}
                          <div className="block -mt-px text-sm font-semibold truncate whitespace-nowrap">
                            {truncateString(val.nazwa_odb, 28)}
                          </div>
                          <div className={`block text-xs text-left group-hover:text-gray-100 ${val.symbol_odb == chooseBranch ? 'text-gray-100' : 'text-gray-600'}`}>
                            {val.kod} {val.miejsc} <br />
                            {val.odb_ul} {val.odb_nr_dom}{val.odb_nr_lok != "" ? "/"+val.odb_nr_lok: ""}
                          </div>
                        </div>
                      </button>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBranch;
