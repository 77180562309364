import { useState, useEffect, useRef } from 'react';
import imgNotFound from '../util/imgnotfound'
import imgNotNetwork from '../util/imgnotnetwork';
import styled from 'styled-components';

const Image = styled.img`
  + i {
    content: "";
    position: absolute;
    display: block;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: ${props => props.width || "auto"}px;
    height: ${props => props.height || "auto"}px;
    background-position: center center;
    background-size: contain;
    background-image: url('${props => props.imageLoad}');
    transition: all ease-in-out 0.3s;
    opacity: 1;
  }
  
  :not(.img-load) + i {
    opacity:0;
  }
`;

import './style.css';
import { useGlobalStore } from '../context/global';

// const imageLoad = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

const LazyImage = ({ 
  src, 
  alt, 
  width, 
  height, 
  symbol, 
  srcLoad = '', 
  handlerLoad = (symbol) => {}, 
  handlerDone = (image) => {}, 
  className = 'border', 
  refresh = 0,
  ignoreLight = 0,
}) => {
  const [globalStore] = useGlobalStore();
  const [isLoad, setIsLoad] = useState(false);
  const [imageLoad, setImageLoad] = useState(null);
  const [imageSrc, setImageSrc] = useState(imageLoad || src || imgNotFound);
  const imageRef = useRef(null);

  const onLoad = event => {
    setIsLoad(false);
    setTimeout(() => event.target.classList.remove('img-load'), 0);
    // setTimeout(() => handlerDone(event.target.src), 1000);
    setTimeout(() => setImageLoad(event.target.src), 0);
  };

  const onError = event => {
    setIsLoad(false);
    setTimeout(() => {
      event.target.classList.add('img-error');
      event.target.src = imgNotFound;
    }, 0);
  };

  const preHandlerLoad = (symbol) => {
    if (globalStore.light == 1 && ignoreLight == 0) {
      return Promise.resolve(imgNotNetwork);
    }

    const connectionType = navigator?.connection;
    if (connectionType) { 
      if (connectionType?.effectiveType == 'slow-2g' 
        || connectionType?.effectiveType == '2g'
        || connectionType?.effectiveType == '3g')
      return Promise.resolve(imgNotNetwork);
    }

    return handlerLoad(symbol);
  };

  useEffect(() => {
    
  }, [srcLoad]);

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef.current && imageSrc) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (
                !didCancel
                && (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                if (isLoad !== true) {
                  setIsLoad(true);
                  preHandlerLoad(symbol).then(data => {
                    data && setImageSrc(data);
                  });
                }

                if (observer && observer.unobserve && imageRef.current)
                  observer.unobserve(imageRef.current);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '0%',
          },
        );

        observer.observe(imageRef.current);
      } else {
        // setIsLoad(true);
        preHandlerLoad(symbol).then(data => {
          setImageSrc(data);
        });
      }
    }
    return () => {
      didCancel = true;

      if (observer && observer.unobserve && imageRef.current) {
        observer.unobserve(imageRef.current);
      }
      //setImageRef(null);
      //setImageSrc(src);
      setIsLoad(false);
    };
  }, [imageRef, imageSrc, refresh]);

  return (
    <>
    {srcLoad != "" && (
      <div className='relative'>
        <Image
          ref={imageRef}
          src={imageSrc}
          alt={alt}
          onLoad={onLoad}
          onError={onError}
          width={width}
          height={height}
          className={`img-lazy img-load ${className}`}
          imageLoad={imageLoad}
        />
        <i />
      </div>
      )}
      {srcLoad == "" && (
        <img
          ref={imageRef}
          src={imageSrc}
          alt={alt}
          onLoad={onLoad}
          onError={onError}
          width={width}
          height={height}
          className={`img-lazy img-load ${className}`}
        />
      )}
    </>
  );
};

export default LazyImage;
