import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import storageSession from "../util/storage";

const langList = ['pl', 'en'];
// const htmlLang = document.documentElement.lang || "pl";
const htmlLang = storageSession.get('global-lang') || "pl"

i18n
  .use(Detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "pl",
    lng: htmlLang, // pl
    // saveMissing: true,
    // debug: true,
    interpolation: {
      escapeValue: false
    },
    whitelist: langList,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      // wait: true,
      useSuspense: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b']
    }
  });

i18n.on('languageChanged', (lng) => {
  // console.log("i18n change lang", lng);
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;