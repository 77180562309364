import Header from '../../components/header';
import Footer from '../../components/footer';

// import CatalogNav from '../../catalog/components/CatalogHeader';

import ScrollArrow from '../../components/scrollarrow';
import PopupDialog from '../modal/PopupDialog';

import UserConfig from "../context/userconfig";

const RouteMain = (props) => (
  <UserConfig>
    <PopupDialog hash="_" html="..." enable={false} />
    <Header />
    <div className="container flex flex-row items-stretch content-start justify-start flex-grow">
      {props.children}
    </div>
    <Footer />
    <ScrollArrow />
    <div id="show-article" />
    <div id="show-modal" />
  </UserConfig>
);

export default RouteMain;
