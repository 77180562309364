import { useEffect, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import { useCookies } from 'react-cookie';

import IconExit from '../icon/IconExit';
import IconButton from './iconbutton';
import { uid } from '../util';

const PopupDialog = ({
  html = "",
  hash = uid(),
  enable = false,
  staleTime = 20, // minutes - czas trzymania
  delayTime = 2, // seconds - czas opóżnienia
  // repeatTime = -1, // -1 = nieskonczonosc
}) => {
  if (enable == false) return "";

  const cookieName = "global-popup-dialog";
  const [cookies, setCookie] = useCookies([cookieName]);
  const [showPopup, setShowPopup] = useState(false);

  const getTimestamp = () => Math.floor(Date.now() / 1000);

  const checkCookieExpire = () => {
    const cookie = cookies[cookieName];
    if (cookie == undefined)
      return true;

    const cookieValue = cookie.split("|");
    if (cookieValue == undefined || cookieValue.length < 2) 
      return true;

    if (Math.round(((+cookieValue[0]) - getTimestamp()) / 60) < 1)
      return true;

    if (cookieValue[1] != hash)
      return true;

    if (staleTime <= 0)
      return true;

    return false;
  };

  const renderCookie = () => {
    const container = document.getElementById('show-modal');

    const onClose = () => {
      setShowPopup(false);
      unmountComponentAtNode(container);
    };

    render(
      <div
        className="fixed inset-0 z-50 flex overflow-auto transition bg-gray-800 bg-opacity-50 modal-bg"
        data-key={hash}
      >
        <div className={`relative w-full max-w-3xl max-h-screen m-auto flex-col flex rounded-lg shadow transition bg-white`}>
          <div className='p-2 overflow-hidden'>
            <div className="flex justify-center w-full mx-auto min-h-40">
              {ReactHtmlParser(html)}
            </div>
          </div>
          <span className="absolute top-3 right-3">
            <IconButton onClick={onClose}>
              <IconExit color={"text-red-500"} />
            </IconButton>
          </span>
        </div>
      </div>,
      container
    );
  };

  useEffect(() => {
    if (showPopup == true || !checkCookieExpire()) return;
    setShowPopup(true);

    const time = getTimestamp() + (60 * staleTime) + delayTime;
    setCookie(cookieName, time + "|" + hash, { path: '/', maxAge: time });
    setTimeout(() => renderCookie(), delayTime * 1000);
  }, [cookies, document.location.pathname]);

  return "";
};

export default PopupDialog;
