import api from './service/api';
import { convertRgbToHex, applyColors, parseJSON, getUserObj } from './util';

import AlertDialog from './modal/AlertDialog';

// type globalStore = {
//   setUser: (user: any) => {},
//   setSearch: (search: string) => {},
//   setFilter: (filter: object) => {},
//   setColors: (colors: object) => {},
// };

async function getColors() {
  const response = await api.getColors();
  console.log('COLORS', response?.data);
  return response?.data;
}

async function getModules() {
  const response = await api.getMenuModules();
  console.log('MODULES', response?.data);
  return response?.data;
}

async function getOsoba() {
  const response = await api.getOsoba();
  console.log('OSOBA', response?.data);
  return response?.data;
}

const getUserData = async () => {
  const rOsoba = await getOsoba();

  if (!rOsoba || rOsoba?.status !== 0) {
    AlertDialog({
      key: 'user-data-err',
      title: 'Komunikat',
      message: 'Błąd pobrania profilu użytkownika! ' + (rOsoba?.info || ""),
    });
    return false;
  }

  const dataOsoba = parseJSON(rOsoba?.dane);
  const userObj = getUserObj(dataOsoba);

  const resColors = await getColors();
  if (resColors?.status == 0) {
    const dataColors = parseJSON(resColors?.dane);
    if (Array.isArray(dataColors) && dataColors.length > 0) {
      userObj.colors.primary = convertRgbToHex(dataColors[0].cprimary);
      userObj.colors.secondary = convertRgbToHex(dataColors[0].csecondary);
      userObj.colors.highlight = convertRgbToHex(dataColors[0].chighlight);

      applyColors(userObj.colors);
    }
  }

  const resModules = await getModules();
  if (resModules?.status == 0) {
    const dataModules = parseJSON(resModules?.dane);
    dataModules.length > 0 && dataModules.forEach(el => {
      userObj.modules[+el.id-1] = 1;
    });
    // userObj.modules = parseFloat(dataModules.limit);
  }

  return userObj;
};

export default getUserData;
