import { useState } from 'react';
import { useGlobalStoreUser } from '../context/global';
import { IconExit } from '../icon';

const ExtraInfo = () => {
  const [globalStoreUser] = useGlobalStoreUser();
  const [isDisplay, setIsDisplay] = useState(true);

  if (!!globalStoreUser?.extra_info != true) return '';
  if (globalStoreUser?.extra_info.replace(/<[^>]+>/g, '') == '') return '';

  const onClose = () => {
    setIsDisplay(false);
  }

  return (
    <>
      {isDisplay && (
        <header className="extra-info relative">
          <div className="h-6 leading-5 py-0.5 bg-orange-400 text-white text-center font-medium text-sm">
            {globalStoreUser?.extra_info.replace(/<[^>]+>/g, '') || "..."}
          </div>
          <button
            className='absolute right-0 top-0 p-1 px-2'
            onClick={onClose}
          >
            <IconExit size={4} color={"text-white"} />
          </button>
        </header>
      )}
    </>
  );
}

export default ExtraInfo;
