
import { useState, useEffect } from "react";

export default function useDebounce(value, delay, cancel = false) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!cancel) setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, cancel]);

  return debouncedValue;
};
