// lazyRetry for react lazy, fixing loadChunkError
import { lazy } from 'react';

const lazyRetry = (componentImport) =>
  lazy(() => new Promise((resolve, reject) => {
    const bufferEncode = Buffer.from(componentImport.toString()).toString('base64');
    const storageKey = `retry-lazy-refreshed${bufferEncode}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload();
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        }
        reject(error);
      });
  }));

export default lazyRetry;
