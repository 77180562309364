const IconCart = ({ size = 4, color = "black", className = '', loading = false, fill = false }) => (
  <svg className={`w-${size} h-${size} 
    ${color == 'black' ? '' : ''} 
    ${color == 'gray' ? 'text-gray-500' : 'group-hover:text-primary'} 
    ${color == 'primary' ? 'text-primary' : 'group-hover:text-primary'} 
    ${color == 'green' ? 'text-green-500' : 'group-hover:text-primary'} 
    ${color == 'red' ? 'text-red-500' : 'group-hover:text-primary'} 
    ${color == 'blue' ? 'text-blue-500' : 'group-hover:text-primary'} 
    ${color == 'yellow' ? 'text-yellow-500' : 'group-hover:text-primary'} 
    ${color == 'purple' ? 'text-purple-500' : 'group-hover:text-primary'}
    ${className}`}
    viewBox="0 0 512 512"
    fill="currentColor"
  >
    {loading && (
      <defs>
        <linearGradient id="cart-gradient" x1="0%" y1="0%" x2="100%" y2="0">
          <stop offset="0%" style={{ stopColor: '#b827fc' }} />
          <stop offset="25%" style={{ stopColor: '#2c90fc' }} />
          <stop offset="50%" style={{ stopColor: '#b8fd33' }} />
          <stop offset="75%" style={{ stopColor: '#fec837' }} />
          <stop offset="100%" style={{ stopColor: '#fd1892' }} />
        </linearGradient>
        <pattern id="cart-pattern" x="0" y="0" width="300%" height="100%" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="150%" height="100%" fill="url(#cart-gradient)">
            <animate
              attributeType="XML"
              attributeName="x"
              from="0"
              to="150%"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="-150%" y="0" width="150%" height="100%" fill="url(#cart-gradient)">
            <animate
              attributeType="XML"
              attributeName="x"
              from="-150%"
              to="0"
              dur="1s"
              repeatCount="indefinite"
            />
          </rect>
        </pattern>
      </defs>
    )}
    <g transform="scale(-1,1)" transform-origin="center" style={{ transform: "scale(-1,1)"}}>
    <polyline points="492 88 98 88 144 280 450 280" fill={`${fill ? "currentColor" : "transparent"}`} />	
      <path 
        fill={`${loading ? 'url(#cart-pattern)' : ''}`} 
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M508.764,74.466c-2.803-3.503-7.046-5.542-11.533-5.542H104.152L93.16,21.294c-1.547-6.701-7.514-11.448-14.391-11.448 
          h-64C6.613,9.846,0,16.459,0,24.616s6.613,14.769,14.769,14.769H67.02l74.612,323.321c1.546,6.701,7.514,11.448,14.391,11.448 
          h295.915c8.157,0,14.769-6.613,14.769-14.769c0-8.157-6.613-14.769-14.769-14.769H167.773l-11.36-49.231h296.511 
          c6.908,0,12.893-4.788,14.409-11.527L511.64,86.935C512.625,82.557,511.567,77.97,508.764,74.466z M441.108,265.846H149.596 
          L110.969,98.462h367.8L441.108,265.846z"
      />
      <path fill={`${loading ? 'url(#cart-pattern)' : ''}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M216.615,393.846c-29.86,0-54.154,24.293-54.154,54.154s24.293,54.154,54.154,54.154c29.86,0,54.154-24.293,54.154-54.154 S246.476,393.846,216.615,393.846z M216.615,472.616C203.042,472.616,192,461.573,192,448s11.042-24.615,24.615-24.615 c13.573,0,24.615,11.042,24.615,24.615S230.188,472.616,216.615,472.616z" />
      <path fill={`${loading ? 'url(#cart-pattern)' : ''}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M393.846,393.846c-29.86,0-54.154,24.293-54.154,54.154s24.293,54.154,54.154,54.154c29.86,0,54.154-24.293,54.154-54.154 S423.707,393.846,393.846,393.846z M393.846,472.616c-13.573,0-24.615-11.042-24.615-24.615s11.042-24.615,24.615-24.615 c13.573,0,24.615,11.042,24.615,24.615S407.419,472.616,393.846,472.616z" />
    </g>
  </svg>
);

export default IconCart;
