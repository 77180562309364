import Header from '../../admin/components/header';
import Side from '../../admin/components/side';
import Footer from '../../admin/components/footer';

const RouteAdmin = (props) => (
  <>
    <Header />
    <Side />
    {props.children}
    <Footer />
  </>
);

export default RouteAdmin;