import React from "react";

const useFocusOnMount = (ref, delay = 0) => {
  React.useEffect(() => {
    setTimeout(() => {
      if (ref.current) ref.current.focus();
    }, delay);
  }, [ref.current]);
};

export default useFocusOnMount;
