import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Link = forwardRef(({ to, ...rest }, ref) => {
  const navigate = useNavigate();
  const href = to;
  
  const props = {
    ...rest,
    children: undefined,
    href,
    onClick: (event) => {
      // if (!target || target === '_self') return;
      event.preventDefault();
      navigate(to); // or redirect
    },
  };

  return (
    <a {...props} ref={ref}>
      {rest.children}
    </a>
  );
});

export default Link;
