const IconArtPreview = ({ size = 4, color = "gray", className = '' }) => (
  <svg className={`w-${size} h-${size} 
    ${color == 'gray' ? 'text-gray-500' : 'group-hover:text-primary'} 
    ${color == 'primary' ? 'text-primary' : 'group-hover:text-primary'} 
    ${color == 'green' ? 'text-green-500' : 'group-hover:text-primary'} 
    ${color == 'red' ? 'text-red-500' : 'group-hover:text-primary'} 
    ${color == 'blue' ? 'text-blue-500' : 'group-hover:text-primary'} 
    ${color == 'yellow' ? 'text-yellow-500' : 'group-hover:text-primary'} 
    ${color == 'purple' ? 'text-purple-500' : 'group-hover:text-primary'} 
      transition-all group-hover:scale-125 group-hover:-translate-x-0.5 group-hover:-translate-y-0.5 group-hover:text-primary ${className}`}
    viewBox="0 0 512 512" 
    fill="currentColor"
  >
    <polygon fill="white" points="50,0 335,0 450,120 450,500 50,500" />
    <polygon points="337.393,15.391 333.585,15.391 333.585,121.536 451.215,121.536" />
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="10" d="M453.774,203.483c-8.499,0-15.391,6.89-15.391,15.391v262.345H73.615V30.782h244.579v90.753 
      c0,8.5,6.892,15.391,15.391,15.391h117.63c6.323,0,12.003-3.868,14.32-9.75c2.318-5.882,0.802-12.585-3.822-16.898L347.891,4.135 
      c-0.118-0.111-0.248-0.206-0.369-0.312c-0.179-0.157-0.359-0.312-0.545-0.462c-0.191-0.152-0.388-0.296-0.585-0.437 
      c-0.203-0.146-0.405-0.291-0.614-0.428c-0.189-0.123-0.385-0.237-0.579-0.352c-0.231-0.137-0.462-0.272-0.7-0.396 
      c-0.183-0.095-0.369-0.18-0.556-0.268c-0.259-0.122-0.516-0.243-0.782-0.351c-0.18-0.072-0.366-0.135-0.549-0.202 
      c-0.276-0.1-0.551-0.2-0.834-0.285c-0.197-0.058-0.397-0.103-0.596-0.152c-0.274-0.069-0.546-0.143-0.825-0.199 
      c-0.252-0.049-0.509-0.08-0.765-0.117c-0.232-0.034-0.46-0.075-0.696-0.099C338.399,0.028,337.897,0,337.393,0h-3.809H58.224 
      c-8.499,0-15.391,6.89-15.391,15.391v481.218c0,8.5,6.892,15.391,15.391,15.391h395.552c8.499,0,15.391-6.89,15.391-15.391V218.874 
      C469.165,210.373,462.275,203.483,453.774,203.483z M348.976,106.144V47.236l63.168,58.908H348.976z" />
    <circle fill="white" cx="255.44" cy="270.754" r="98.532" />
    <path d="M255.432,384.629c-29.173,0-58.346-11.104-80.557-33.315c-44.418-44.419-44.418-116.694,0-161.112 
      s116.693-44.421,161.115,0l0,0c44.418,44.418,44.418,116.693,0,161.112C313.78,373.525,284.607,384.629,255.432,384.629z 
      M255.434,187.655c-21.292,0-42.583,8.105-58.791,24.313c-32.418,32.418-32.418,85.163,0,117.581 
      c32.419,32.418,85.168,32.418,117.581,0c32.418-32.418,32.418-85.163,0-117.581C298.015,195.76,276.724,187.655,255.434,187.655z" />
    <path d="M386.05,416.768c-3.94,0-7.877-1.502-10.883-4.508l-60.944-60.944c-6.01-6.01-6.01-15.756,0-21.766 
      c6.013-6.009,15.756-6.01,21.767,0l60.944,60.944c6.01,6.01,6.01,15.756,0,21.766C393.929,415.264,389.989,416.768,386.05,416.768z" />
  </svg>
);

export default IconArtPreview;
